import { Card, Col, Row } from "react-bootstrap";
// import Chart from "react-apexcharts";
import type { Simulator } from "types";
import { Link } from "react-router-dom";
import { isDevelopment } from "lib/env";
import css from "./simulatorCardStyle.module.css"
import { randInt } from "three/src/math/MathUtils";
import placeholder_geometry from "assets/images/placeholder_geometry.png"
import { useState } from "react";

export type SimulatorCardProps = {
  simulator: Simulator;
};

export const SimulatorCard = (props: SimulatorCardProps) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const simulator = props.simulator;
  const filenameUseAsThumbnail = simulator && simulator?.modulus_components && simulator?.modulus_components?.geometries && 
    Object.values(simulator?.modulus_components?.geometries)?.map((geo) => geo?.data)?.map((data) => {
      if (data?.thumbnail) {
        return data?.file_name;
      }
    })?.filter((filename) => filename !== undefined)?.[0];

  const thumbnails = simulator?.geometries?.map((geo) => geo?.geometries?.map((geo) => geo?.thumbnail_url))?.flatMap((geo) => geo);
  const thumbnailUrlUser = thumbnails?.find((thumbnail) => thumbnail?.includes(filenameUseAsThumbnail?.split(".")?.[0]));
  const randomThumbnailIndex = randInt(0, thumbnails?.length - 1);
  const thumbnailUrl = thumbnailUrlUser ? thumbnailUrlUser : thumbnails?.[randomThumbnailIndex];

  // isDevelopment && console.log(simulator)

  return (
    <Link to={`/model-engineer/simulators/${simulator.id}`}>
      <Card className="d-block card-link simulator-card">
        <Card.Body>
          <div className="top-row">
            <div className={css.insideCardContainer}>
              <h4 className="mt-0 mb-2">
                <span className="text-title">
                  {simulator.label}
                </span>
              </h4>

              {/* <div className="accuracy-container">
                <span>76<span>%</span></span>
                <span>Accuracy</span>
              </div> */}

              <img src={thumbnailUrl ? (isImgLoaded ? thumbnailUrl : placeholder_geometry) : placeholder_geometry} alt="thumbnail" className={css.thumbnailSimulator} onLoad={() => setIsImgLoaded(true)}/>
              {simulator?.is_verified && <Row>
                <Col style={{fontSize: "11px"}}>
                  <i className="mdi mdi-checkbox-marked-circle text-info" style={{marginRight: "2px"}}></i>
                  <span className="text-info">DimensionLab Verified</span>
                </Col>
              </Row>}
            </div>

            {/* <div className="chart-container">
              <Chart 
                type="radialBar"
                series={[76]}
                height={140}
                options={{
                  labels: [''],
                  chart: {
                    offsetY: 13,
                    offsetX: 5
                  },
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: "30%",
                        margin: 0
                      },
                      dataLabels: {
                        value: {
                          show: false,
                        } 
                      },
                    },
                  },
                  fill: {
                    type: 'gradient',
                    gradient: {
                      type: 'horizontal',
                      gradientToColors: ['#D28DF7'],
                      opacityFrom: 1,
                      opacityTo: 1,
                      stops: [0, 100]
                    }
                  },
                }}
              />
            </div> */}
          </div>

          {/* <div className="stats">
            <div>
              <span>LOSS</span>
              <span>{simulator.loss ? simulator.loss : "N/A"}</span>
            </div>
            <div>
              <span>MSE</span>
              <span>{simulator.mse ? simulator.mse : "N/A"}</span>
            </div>
          </div> */}

          <p className="text-muted font-13 mb-0">
            {simulator?.desc?.length > 100 ? simulator?.desc?.slice(0, 100) + "..." : simulator?.desc}
          </p>
        </Card.Body>
      </Card>
    </Link>
  );
};
