import { Button, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Simulator } from "types";

import "./topbar.css";
import { useUpdateSimulator } from "mutations";
import { serializeSimulator } from "modulus-interop/schema";
import { useModulusInterop } from "modulus-interop/reactflow";
import { ModulusHydraConfig } from "modulus-interop/types";
import { useReactFlow } from "reactflow";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuerySimulatorTutorialData } from "queries";

type VisualUITopbarProps = {
  simulator: Simulator;
};

export const VisualUITopbar = ({ simulator }: VisualUITopbarProps) => {
  const { serializeNodes } = useModulusInterop();
  const { setNodes, setEdges } = useReactFlow();
  const { user } = useAuth0();
  const { data: simulatorData } = useQuerySimulatorTutorialData();

  const updateMutation = useUpdateSimulator();

  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleSave = (e: any) => {
    e.preventDefault();

    const conf = simulator?.modulus_components?.conf ?? {} as ModulusHydraConfig;
    const modulusComponents = serializeNodes();
    const serializedSimulator = serializeSimulator(simulator.id, conf, modulusComponents);
    console.log(serializedSimulator)

    updateMutation.mutate({
      id: simulator.id,
      modulus_components: serializedSimulator,
    });
  };

  const clear = (e: any) => {
    e.preventDefault();

    setNodes([]);
    setEdges([]);
  }

  return (
    <Stack direction="horizontal" className="visual-ui-node-top-bar-list" data-tour='tour_nodes_panel'>
      <div
        className="meng-visualui-topbar-node equation"
        onDragStart={(event) => onDragStart(event, "equation")}
        draggable={true}>
        Equation
      </div>

      <div
        className="meng-visualui-topbar-node architecture"
        onDragStart={(event) => onDragStart(event, "architecture")}
        draggable={true}>
        Neural net
      </div>

      <div
        className="meng-visualui-topbar-node geometry-group"
        onDragStart={(event) => onDragStart(event, "geometryGroup")}
        draggable={true}>
        Geometry
      </div>

      <div
        className="meng-visualui-topbar-node constraint"
        onDragStart={(event) => onDragStart(event, "constraint")}
        draggable={true}>
        Constraint
      </div>

      <div
        className="meng-visualui-topbar-node dataset"
        onDragStart={(event) => onDragStart(event, "dataset")}
        draggable={true}>
        Dataset
      </div>

      <div className="meng-visualui-topbar-buttons ms-auto">
        {(user?.sub === simulator?.owner || simulatorData?.tutorial?.simulator_tutorial_id === simulator?.id) && 
          <>
            <Button onClick={clear} className="btn clear" disabled={simulatorData?.tutorial?.simulator_tutorial_id === simulator?.id}>
              Clear
            </Button>
            <Button onClick={handleSave} className="btn blue" data-tour='tour_save_button' disabled={simulatorData?.tutorial?.simulator_tutorial_id === simulator?.id}>
              Save
            </Button>
          </>
        }
      </div>
    </Stack>
  );
};

export const SimulatorControlBar = ({ simulator }: { simulator: Simulator }) => {
  const navigate = useNavigate();
  return (
    <Button  
      style={{ 
        backgroundColor: "#94e594", color: "#333333", fontWeight: "bold", padding: "0.8rem 2rem", fontSize: "1rem", borderRadius: "15px 0px 15px 0px", transitionDuration: "0.3s"
      }}
      onMouseEnter={() => {
        const btnElement = document.getElementById("train-btn");
        btnElement.style.backgroundColor = "#63d963";
      }} 
      onMouseLeave={() => {
        const btnElement = document.getElementById("train-btn");
        btnElement.style.backgroundColor = "#94e594";
      }}
      id="train-btn"
      onClick={() =>  navigate(`/model-engineer/environments/new?simulator=${simulator.id}`)}>
      <span>Train <i className="dripicons-rocket" id="ship-train-icon"
        onMouseEnter={() => {
          const btnElement = document.getElementById("ship-train-icon");
          btnElement.style.color = "#333333";
        }} 
        onMouseLeave={() => {
          const btnElement = document.getElementById("ship-train-icon");
          btnElement.style.color = "#333333";
        }}
      ></i></span>
      
    </Button>
  )
}