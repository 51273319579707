import { Dispatch, SetStateAction } from "react";
import { nodeTutorialOrder } from "./simulatorTutorial";
import { ReactourStep } from "reactour";

export const getStepsConfig = (setTourStep: Dispatch<SetStateAction<number>>, setFinished: ((val: boolean) => void)): ReactourStep[] => ([
  {
    selector: "#visual-editor-tour",
    content: () => (
      <div>
        <p>We will <strong style={{color: "rgba(124, 168, 255, 1)"}}>guide you</strong> through creating <strong style={{color: "rgba(124, 168, 255, 1)"}}>your first simulator</strong>.</p>
        <p>We will be simulating a <strong style={{color: "rgba(124, 168, 255, 1)"}}>wind flow</strong> around the <strong style={{color: "rgba(124, 168, 255, 1)"}}>car inside a wind tunnel</strong>.</p>
      </div>
    ),
    action: () => { setTourStep(0); }
  }, 
  {
    selector: "[data-tour='tour_nodes_panel']",
    content: () => (
      <div>
        <p>First, we need to <strong style={{color: "rgba(124, 168, 255, 1)"}}>choose nodes</strong>.</p>
        <p>You can <strong style={{color: "rgba(124, 168, 255, 1)"}}>click and drag</strong> these nodes into the <strong style={{color: "rgba(124, 168, 255, 1)"}}>visual editor</strong>.</p>
      </div>
    ),
    action: () => {
      setTourStep(1);
    }
  },
  {
    selector: "[data-tour='tour_neural_network_node']",
    content: () => (
      <div>
        <p>Let's start with <strong style={{color: "rgba(124, 168, 255, 1)"}}>neural net</strong>.</p>
        <p>This node takes care of the architecture of neural network inside the simulator.</p>
      </div>
    ),
    // action: (node: any) => { window.scrollTo(0, 0); },
    action: () => { setTourStep(2); },
    position: "center",
  },
  {
    selector: "[data-tour='tour_neural_net_settings_panel']",
    content: () => (
      <div>
        <p>With almost every node, you can utilize sidebar <strong style={{color: "rgba(124, 168, 255, 1)"}}>for changing node settings.</strong></p>
        <p>These settings influence the training behaviour.</p>
      </div>
    ),
    action: (node: any) => {
      setTourStep(3);
      window.scrollTo(0, 0);
    },
  },
  {
    selector: "[data-tour='tour_equation_node']",
    content: () => (
      <div>
        <p>This is an <strong style={{color: "rgba(124, 168, 255, 1)"}}>equation</strong> node.</p>
        <p>In case of physics-informed simulators, you can set predefined or custom equation here.</p>
      </div>
    ),
    action: (node: any) => {
      setTourStep(4);
    },
  },
  {
    selector: `.fade.modal.show`,
    content: () => (
      <div>
        <p>Equation node also opens up <strong style={{color: "rgba(124, 168, 255, 1)"}}>equation settings pop-up</strong> via button.</p>
        <p>Here you can choose from predefined equations or create your own.</p>
      </div>
    ),
    action: (node: any) => {
      setTourStep(5);
    },
    stepInteraction: false,
  },
  {
    selector: "[data-tour='tour_geometry_node']",
    content: () => (
      <div>
        <p>Next, we need to <strong style={{color: "rgba(124, 168, 255, 1)"}}>add a geometry</strong> node.</p>
        <p>Within this node you can upload different geometries in various file formats for your simulator.</p>
      </div>
    ),
    action: (node: any) => {
      setTourStep(6);
    }
  },
  {
    selector: `[data-tour='tour_constraint_node_${nodeTutorialOrder[6]}']`,
    content: () => (
      <div>
        <p>And finally, there are <strong style={{color: "rgba(124, 168, 255, 1)"}}>constraint</strong> nodes.</p>
        <p>They connect to geometries and specify interior, boundary or integral constraints for them.</p>
      </div>
    ),
    action: (node: any) => { 
      setTourStep(7);
    },
  },
  {
    selector: "[data-tour='tour_save_button']",
    content: () => (
      <div>
        <p>After modifying simulator, it's best to <strong style={{color: "rgba(124, 168, 255, 1)"}}>save</strong> the simulator, so we don't lose the progress.</p>
        <p>Upon clicking on the <strong style={{color: "rgba(124, 168, 255, 1)"}}>save</strong> button, it gets uploaded and saved.</p>
      </div>
    ),
    action: (node: any) => { 
      setTourStep(8);
    },
  },
  {
    selector: "[data-tour='tour_train_simulator']",
    content: () => (
      <div>
        <p>And finally, we can <strong style={{color: "rgba(124, 168, 255, 1)"}}>train</strong> the simulator we've just created.</p>
        {/* <p>Click on the <strong style={{color: "rgba(124, 168, 255, 1)"}}>train</strong> button.</p> */}
      </div>
    ),
    action: (node: any) => {
      setTourStep(9);
      setFinished(true);
    },
    stepInteraction: true,
  }
]);