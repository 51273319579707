export const showPopup = (status: string) => {
  switch (status) {
    case "completed":
      return false;
    case "not_interested":
      return false;
    case "not_started":
      return true;
    default:
      return true;
  }
}