import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { UpdateUserTutorialData, UserTutorialData } from "types";

const auth0Opts = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

export const useSetUserTutorialData = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<UserTutorialData, Error, UpdateUserTutorialData>({
    mutationFn: async (userTutorialData: UpdateUserTutorialData) => {
      const accessToken = await getAccessTokenSilently(auth0Opts);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/tutorials/set-user-tutorial-data/`, {
        method: "POST",
        body: JSON.stringify(userTutorialData),
        headers: {
          "content-type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!res.ok) {
        const errorRes = await res.json();
        const errorMsg = errorRes?.error || errorRes?.label[0] || "There was an error updating user tutorial data."
        throw new Error(errorMsg);
      }
      return res.json();
    },
    onError: (error, __, ___) => {
      const errorMsg = error.message;
      const capitalisedErrMsg = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
      toast.error(capitalisedErrMsg, {
        theme: 'dark'
      })
      console.error(error);
    },
    onSuccess: (data: UserTutorialData, __, ___) => {
      toast.success("User tutorial data updated successfully.", {
        theme: "dark",
      });
    },
  });
}