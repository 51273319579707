import { Handle, NodeProps, Position, useNodeId } from "reactflow";
import classNames from "classnames";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFileUploader } from "components/VisualUI/FileDropzone/hooks";

const truncateFileName = (fileName: string) => {
  if (fileName.length < 30) return fileName;
  return (fileName.slice(0, 6) + "..." + fileName.slice(fileName.length - 9)).trim();
};

export function GeometryNode({ data }: NodeProps) {
  const nodeId = useNodeId();
  const { removeFileNode } = useFileUploader();

  return (
    <Card className={classNames(["mt-1 shadow-none border mb-0"])}>
      <div className="ps-1 pb-1">
        <Row className="align-items-center">
          <Col className="" xs="8">
            <Link to="#" className="font-10">
              {truncateFileName(data.file_name)}
            </Link>
            <p className="mb-0">
              <span className="font-10">{data.size} {data.airtight && ' - Airtight'}</span>
            </p>
          </Col>
          <Col className="text-end" xs="4">
            <Link to="#" className="btn btn-link text-muted shadow-none">
              <i className="dripicons-cross" onClick={() => removeFileNode(nodeId)} />
            </Link>
          </Col>
        </Row>
      </div>
      <Handle type="target" position={Position.Right} id={data.file_name} />
    </Card>
  );
}
