import { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  Controls,
  Background,
  Panel,
  SmoothStepEdge,
  addEdge,
  updateEdge,
  useNodesState,
  useEdgesState,
  useReactFlow,
} from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { Sidebar } from "./Sidebar";
import { VisualUITopbar } from "./Topbar";
import { PDENode } from "./nodes/PDENode";
import { GeometryGroupNode } from "./nodes/GeometryGroupNode";
import { GeometryNode } from "./nodes/GeometryNode";
import { ConstraintNode } from "./nodes/ConstraintNode";
import { ValidatorNode } from "./nodes/ValidatorNode";
import { MonitorNode } from "./nodes/MonitorNode";
import { NeuralNetNode } from "./nodes/NeuralNetNode";
import { Visualizer2DImgNode, Visualizer3DNode } from "./nodes/visualization";
import { FloatingEdge } from "./FloatingEdge";
import { useUndoRedo } from "./hooks";

import "reactflow/dist/style.css";
import "./styles.css";
import { Simulator } from "types";
import { Button } from "react-bootstrap";
import { useModulusInterop } from "modulus-interop/reactflow";

import { useVisualUIStore } from "components/VisualUI/store";
import DatasetNode from "./nodes/DatasetNode";
import { useAuth0 } from "@auth0/auth0-react";

// reactour
import Tour from "reactour";
import css from "styles/app-tour.module.css";
import { getStepsConfig } from "appConstants/TutorialSteps";
import { useQuerySimulatorTutorialData } from "queries";
import { useSetUserTutorialData } from "mutations/tutorial";

const snapGrid: [number, number] = [10, 10];

const nodeTypes = {
  equation: PDENode,
  architecture: NeuralNetNode,
  geometryGroup: GeometryGroupNode,
  geometry: GeometryNode,
  constraint: ConstraintNode,
  dataset: DatasetNode,
  validator: ValidatorNode,
  monitor: MonitorNode,
  viz2DImg: Visualizer2DImgNode,
  viz3D: Visualizer3DNode,
};

const edgeTypes = { floating: FloatingEdge, smoothstep: SmoothStepEdge };

type VisualUIProps = {
  simulator: Simulator;
};

export function VisualUI({ simulator }: VisualUIProps) {
  const edgeUpdateSuccessful = useRef(true);
  const reactFlowWrapper = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();
  const { deserializeNodes } = useModulusInterop();
  const { settingsSidebarNodeId, setSidebarNodeId } = useVisualUIStore();
  const { getEdges, getNodes } = useReactFlow();
  const [isPasteEnabled, setIsPasteEnabled] = useState(false);
  const { user } = useAuth0();
  const { 
    isLoading: isSimulatorTutorialDataLoading,
    isError: isSimulatorTutorialDataError,
    data: simulatorTutorialData,
    error: simulatorTutorialDataError,
    refetch: refetchSimulatorTutorialData,
  } = useQuerySimulatorTutorialData();
  const [isGuide, setIsGuide] = useState(simulatorTutorialData?.tutorial?.simulator_tutorial_id === simulator?.id);
  const [isTourOpen, setIsTourOpen] = useState(isGuide);
  const [tourStep, setTourStep] = useState(0);
  const [isTourFinished, setIsTourFinished] = useState(false);
  const updateUserTutorialData = useSetUserTutorialData();
  const visualEditorRef = useRef<HTMLDivElement>(null);
  const [isVisualEditorVisible, setIsVisualEditorVisible] = useState(false);

  useEffect(() => {
    setIsGuide(simulatorTutorialData?.tutorial?.simulator_tutorial_id === simulator?.id);
  }, [simulatorTutorialData])

  useEffect(() => {
    if (isVisualEditorVisible) { // only start tour if the whole thing is visible in viewport
      setIsTourOpen(isGuide);
    }
  }, [isGuide, isVisualEditorVisible])

  // this takes care of scrolling such that the visual editor is visible in the viewport
  useEffect(() => {
    if (isGuide) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisualEditorVisible(entry.isIntersecting);
        },
        { threshold: 1 }
      );
      if (visualEditorRef.current) {
        observer.observe(visualEditorRef.current);
      }
      return () => {
        if (visualEditorRef.current) {
          observer.unobserve(visualEditorRef.current);
        }
      };
    }
  }, [visualEditorRef]);

  useEffect(() => {
    if (isGuide) {
      if (isVisualEditorVisible && visualEditorRef.current) {
        visualEditorRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isVisualEditorVisible]);

  const handleSetTourFinished = (val: boolean) => {
    setIsTourFinished(val);
  }

  const steps = getStepsConfig(setTourStep, handleSetTourFinished);

  const handleUpdateUserTutorialData = async () => {
    await updateUserTutorialData.mutateAsync({
      tutorials: {
        simulator: {
          simulator_id: simulator.id,
          status: "completed",
        }
      }
    }).then(() => {
      refetchSimulatorTutorialData();
    })
  }

  useEffect(() => {
    if (isTourFinished) {
      handleUpdateUserTutorialData();
    }
  }, [isTourFinished])

  const onConnect = useCallback(
    (params) =>
      setEdges((els) =>
        addEdge(
          {
            ...params,
            animated: true,
            style: {
              strokeWidth: 2,
              stroke: "#aaaaaa",
            },
          },
          els
        )
      ),
    []
  );

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();
      takeSnapshot();

      const reactFlowBounds = (reactFlowWrapper.current as any).getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const id = uuidv4();
      const newNode = {
        id,
        type,
        position,
        data: { id, label: `${type} node` } as any,
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const handleCopy = (event: any) => {
    const nodes = getNodes();
    const selectedNodes = nodes.filter((node) => node.selected);

    if (selectedNodes.length === 0) {
      setIsPasteEnabled(false);
      return;
    }
    setIsPasteEnabled(true);
    navigator.clipboard.writeText(JSON.stringify(selectedNodes));
  }

  const handlePaste = (event: any) => {
    const nodes = getNodes();

    navigator.clipboard.readText().then((text) => {
      const nodes = JSON.parse(text);

      nodes.forEach((node: any) => {
        // new node placement is slightly shifted of the original one
        const position = {
          x: node.position.x + 30,
          y: node.position.y + 30,
        }

        const id = uuidv4();
        const newNode = {
          ...node,
          id,
          position,
          data: { ...node.data, id, label: `${node.type} node` } as any,
        };

        setNodes((nds) => nds.concat(newNode));
      });
    });

    navigator.clipboard.writeText("");
    setIsPasteEnabled(false);
    const unselectedNodes = nodes.map((node) => {
      node.selected = false;
      return node;
    });

    setNodes(unselectedNodes);
  }
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges(updateEdge(oldEdge, newConnection, edges));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges(getEdges().filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  useEffect(() => {
    if (simulator.modulus_components) {
      deserializeNodes(simulator.modulus_components);
    }
  }, [simulator.modulus_components]);

  useEffect(() => {
    setIsSidebarOpen(!settingsSidebarNodeId ? false : true)
  }, [settingsSidebarNodeId])

  // restores the cached reactflow instance from localstorage
  // if there was an error logged from attempting to save the simulator
  useEffect(() => {
    if (sessionStorage.getItem("sim-save-error") === "true") {
      
      if (localStorage.getItem("nodes-data")) {
        const flow = JSON.parse(localStorage.getItem("nodes-data"));

        if (flow) {
          setNodes(flow.nodes || []);
          setEdges(flow.edges || []);
          sessionStorage.removeItem("sim-save-error");
          localStorage.removeItem("nodes-data");
        }
      }
    }
  }, [nodes])

  // caches the whole reactflow instance to localstorage
  // every time the nodes change
  useEffect(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      localStorage.setItem("nodes-data", JSON.stringify(flow));
    }
  }, [nodes])

  useEffect(() => {
    const clickOnElement = (element: HTMLElement) => {
      if (element) {
        element.click();
      }
    }
    const closeSidebar = () => {
      setSidebarNodeId("");
    }

    if (isTourOpen) {
      const btnOpenEquationPopup = document.getElementById("tour_button_change_equation");
      const btnCloseEquationPopup = document.getElementById("tour_equation_node_popup_close");
      const btnOpenSidebarSettings = document.getElementById("tour_btn_open_sidebar_settings");

      switch (tourStep) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        case 3:
          clickOnElement(btnCloseEquationPopup);
          closeSidebar();
          clickOnElement(btnOpenSidebarSettings);
          break;
        case 4:
          closeSidebar();
          clickOnElement(btnCloseEquationPopup);
        break;
        case 5:
          clickOnElement(btnOpenEquationPopup);
          break;
        case 6:
          clickOnElement(btnCloseEquationPopup);
          closeSidebar();
          break;
        default:
          break;
      }
    }
  }, [tourStep, isTourOpen])

  // lock viewport (prevent scrolling) when tour is in-progress
  useEffect(() => {
    if (isTourOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isTourOpen])

  return (
    <div ref={visualEditorRef}>
      <VisualUITopbar simulator={simulator} />
      <div className="meng-visualui" id="visual-editor-tour">
        <div className="reactflow-wrapper"  ref={reactFlowWrapper}>
          {isSidebarOpen ? <Sidebar setIsSidebarOpen={setIsSidebarOpen} /> : null}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            onConnect={onConnect}
            onInit={setReactFlowInstance as any}
            onDrop={onDrop}
            onDragOver={onDragOver}
            snapToGrid={true}
            snapGrid={snapGrid}
            fitView={true}
            onPaneClick={(event) => {
              setSidebarNodeId("");
            }}
            onNodeClick={(event, node) => {
              if (node.type === "geometryGroup" || node.type === "dataset") {
                setSidebarNodeId("")
                return
              }
              // check if 'node click' was from 'delete node button' and if yes,
              // don't set back the node id for it
              if ((event.target as Element).classList.contains('dripicons-cross')) {
                setSidebarNodeId("");
                return;
              } else {
                setSidebarNodeId(node.id);
              }
            }}
            proOptions={{
              hideAttribution: true,
            }}>
            <Controls />
            <Background color="#c7c7c7" gap={20} />
            <Panel position="bottom-center">
              <div className="button-group">
                <Button disabled={canUndo} size="sm" onClick={undo}>
                  undo
                </Button>
                <Button disabled={canRedo} size="sm" onClick={redo}>
                  redo
                </Button>
                <Button size="sm" onClick={(event) => handleCopy(event)}>
                  copy
                </Button>
                <Button size="sm" onClick={(event) => handlePaste(event)} disabled={!isPasteEnabled}>
                  paste
                </Button>
              </div>
            </Panel>
          </ReactFlow>
        </div>
      </div>
      {user?.sub !== simulator?.owner && <p className="text-muted mt-2" style={{marginBottom: "0", display: "flex", alignItems: "center"}}>Note: changes won't be saved, you are not the owner.</p>}
      <Tour steps={steps} onRequestClose={() => setIsTourOpen(false)} isOpen={isTourOpen} className={css.tourWindow} accentColor="rgba(124, 168, 255, 1)" disableInteraction closeWithMask={false} />
    </div>
  );
}
